import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      ctaText="Get Access"
      offerBanner={
        <>
          ✔ Discount applied!
          <br />
          Special offer for a limited time only.
        </>
      }
      video={videos.ca_men_in_blazers.videoUrl}
      title="Watch Men in Blazers with Fubo"
      subtitle="HOME OF THE PREMIER LEAGUE & OTHER TOP LEAGUES"
      tagline="Stream it all live anywhere, from any device"
      plan="soccer"
      networkSet="canada"
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
      logoHeight="90px"
      offerCode="canada-quarter-essentials-men-in-blazers"
      forceAnchorLink={false}
      planCardOverride="menInBlazersOffer"
      preselectPlanLength="quarterly"
    />
  )
}
