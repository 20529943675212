import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAnalyticsProperties,
  selectAnalyticsPropertiesReady,
} from 'src/modules/reducers/analytics'
import { updateParams } from 'src/modules/reducers/params'
import { track } from 'src/modules/analytics/track'

// Imports for A/B Testing
import { useExperiment, emitter, experimentDebugger } from '@marvelapp/react-ab-test'
import MatchTicker from 'src/components/common/MatchTicker'
experimentDebugger.enable()
emitter.defineVariants('MatchTicker', ['MatchTicker-control', 'MatchTicker-treatment'])
// End A/B Testing Code

const MatchTickerTestContainer = ({ lang, market, sportId, leagueName, leagueId, ctaText }) => {
  // A/B Test code
  const analyticsProperties = useSelector(selectAnalyticsProperties)
  const dispatch = useDispatch()

  const abtestParam = {
    ab_campaign: 'MatchTicker',
  }
  useEffect(() => {
    dispatch(updateParams(abtestParam))
  }, [])

  const { selectVariant } = useExperiment('MatchTicker')
  const tabVariant = selectVariant({
    'MatchTicker-control': false,
    'MatchTicker-treatment': true,
  })

  const [testVariant, setTestVariant] = React.useState(false)

  useEffect(() => {
    setTestVariant(tabVariant)
  }, [tabVariant])

  const analyticsPropertiesReady = useSelector(selectAnalyticsPropertiesReady)

  useEffect(() => {
    if (testVariant !== undefined && analyticsPropertiesReady) {
      const experimentProperties = {
        experiment_id: 'MatchTicker',
        variation_id: emitter.getActiveVariant('MatchTicker'),
      }
      const eventProperties = {
        ...analyticsProperties,
        ...experimentProperties,
      }
      track('experiment_viewed', eventProperties)
    }
  }, [testVariant, analyticsPropertiesReady])
  // End A/B Test code

  if (testVariant) {
    return (
      <MatchTicker
        lang={lang}
        market={market}
        sportId={sportId}
        leagueName={leagueName}
        leagueId={leagueId}
        ctaText={ctaText}
      />
    )
  } else {
    return null
  }
}

export default MatchTickerTestContainer
