import React from 'react'

export const laPlanData = channels => {
  return {
    en: {
      plan: 'latino',
      title: 'Latino Plan',
      subtitle: 'TV in Spanish, live soccer and other sports, shows, news, and more.',
      tagline: 'The best Spanish language TV, live soccer, news, entertainment and more.',
      price: '$32.99/mo',
      discountPrice: '$19.99/mo',
      listItems: [
        { available: true, text: '60+ channels and 1000 sports events.' },
        { available: true, text: 'Unlimited Cloud DVR.' },
        { available: true, text: '2 Screens at once with Standard Share.' },
        { available: true, text: 'Liga MX, UEFA Champions League, La Liga, and more sports.' },
      ],
      footnote: {
        // monthly: '',
        monthly:
          '*Offer valid for the first month. Renews at $32.99/mo. Additional taxes and fees may apply. Cancel anytime.',
        quarterly:
          '*Prepaid for 3 months at $74.97. Renews at $99/3mo. Additional taxes and fees may apply. Cancel anytime.',
      },
    },
    es: {
      plan: 'latino',
      title: 'Plan Latino',
      subtitle:
        'TV en español, fútbol en vivo y otros deportes, telenovelas, noticias y mucho más.',
      tagline: 'TV en español, fútbol en vivo, telenovelas, noticias y mucho más.',
      price: '$32.99/mes',
      discountPrice: '$19.99/mes',
      listItems: [
        { available: true, text: 'Más de 60 canales y 1000 eventos deportivos.' },
        { available: true, text: 'Espacio Ilimitado en tu Cloud DVR.' },
        { available: true, text: '2 Pantallas a la vez con Standard Share.' },
        { available: true, text: 'Liga MX, UEFA Champions League, La Liga, y mucho más deportes.' },
      ],
      footnote: {
        // monthly: '',
        monthly:
          '*Oferta valida solo para el primer mes. Se renueva a $32.99/mes. Pueden aplicarse impuestos y otros cargos. Cancela cuando quieras.',
        quarterly:
          '*Prepago por 3 meses a $74.97. Se renueva a $99/trimestre. Pueden aplicarse impuestos y otros cargos. Cancela cuando quieras.',
      },
    },
  }
}

const offerSlug = {
  monthly: 'us-recu-ga-latino-mo-7d-13amtoff1-v1',
  quarterly: 'latino-bundle-quarterly-v6',
}

export { offerSlug }
