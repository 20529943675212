import React, { useEffect, useState } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

import { emitter } from '@marvelapp/react-ab-test'
import { useABTest } from 'src/modules/hooks/useABTest'

export default props => {
  let teamTitle = 'Watch football and more'
  let pageTitle = 'Watch football and more'

  if (props.teamShort) {
    teamTitle = `Watch ${props.teamShort} football live`
  }

  if (props.team) {
    pageTitle = `Watch ${props.team} football live`
  }

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      poster={
        'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-HORZ-V2.jpg?ch=width&auto=format,compress'
      }
      posterMobile={
        'https://assets.fubo.tv/marketing/lp/images/posters/NFL-PLAYER-STATIC-VERT-V2.jpg?ch=width&auto=format,compress'
      }
      title={teamTitle}
      pageTitle={pageTitle}
      subtitle={'Stream CBS, ESPN, FOX and 200+ live channels'}
      networkSet="nfl"
      // teams
      team={props.team}
      // Remove cable references
      removeCable={true}
      matchtickerSportId="111"
      matchtickerLeagueName="NFL"
      matchtickerLeagueId="191277"
      showRedzone={true}
    />
  )
}
