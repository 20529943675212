import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import ChannelSectionSimple from 'src/components/common/ChannelSectionSimple'
import HeroVideoPlayer from 'src/components/common/HeroVideoPlayer'
import { videos } from 'src/constants/videos'
import BundlePlansCAControlVariant from 'src/components/common/BundlePlansCAByDuration'
import { Helmet } from 'react-helmet'
import BundlePlansCAByPlan from 'src/components/common/BundlePlansCAByPlan'
import TestBundlePlansCAByPlans from 'src/components/common/BundlePlansCAByPlan/testBundlePlansCAByPlan'

const CanadaOneboxTemplateDefault = ({
  location,
  lang = 'en',
  market = 'canada',
  ctaHrefOverride,
  // Offer Code
  offerCode,
  offerBanner,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  ctaText = 'Get Access',
  video,
  videoUrlBase,
  background,
  // Plan Cards
  hasPlanCards = true,
  plan,
  plansTitle,
  plansSubtitle,
  // Network Section
  networkHeadline,
  networkSet = 'canada',
  customNetworks,
  forcePlanLength,
  planCardOverride,
  hasOfferInput,
  soccerPlanCardImage,
  premiumPlanCardImage,

  // Others
  team,
}) => {
  let metaDescription =
    pageDescription ||
    '200+ live channels. Free Cloud DVR. Watch on your phone, Roku, Apple TV, Amazon Fire TV, computer & more. FOX, NBC, CBS, NFL Network, SHOWTIME, beIN SPORTS & more.'
  const sortByPlan = plan === 'soccer' && !forcePlanLength && !planCardOverride && !hasOfferInput

  return (
    <Layout lang={lang} market={market} offerCode={offerCode} offerBanner={offerBanner}>
      <Helmet>
        <link rel="preconnect" crossOrigin="anonymous" href="https://marketing-partners.fubo.tv" />
        <link
          rel="dns-prefetch"
          crossOrigin="anonymous"
          href="https://marketing-partners.fubo.tv"
        />
      </Helmet>
      <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
      <HeroVideoPlayer
        location={location}
        video={video || videos.generic_soccer.videoUrl}
        title={title}
        subTitle={subtitle}
        videoUrlBase={videoUrlBase}
        background={background}
        ctaText={ctaText}
        ctaHrefOverride={ctaHrefOverride}
        logo={logo}
        logoHeight={logoHeight}
      />
      {hasPlanCards && sortByPlan ? (
        <TestBundlePlansCAByPlans
          plan={plan}
          title={plansTitle}
          subtitle={plansSubtitle}
          offerCode={offerCode}
          soccerPlanCardImage={soccerPlanCardImage}
          premiumPlanCardImage={premiumPlanCardImage}
          ctaText={ctaText}
          forcePlanLength={forcePlanLength}
          planCardOverride={planCardOverride}
        />
      ) : (
        <BundlePlansCAControlVariant plan={plan} title={plansTitle} subtitle={plansSubtitle} />
      )}
      <NetworkSection
        lang={lang}
        market={market}
        networkHeadline={networkHeadline}
        networkSet={networkSet}
        customNetworks={customNetworks}
      />
      <ChannelSectionSimple
        lang={lang}
        market={market}
        showAllChannels={true}
        title="Your Fubo channel lineup"
      />
      <DealSection lang={lang} market={market} />
      <PlatformSection lang={lang} market={market} />
      <FAQSection lang={lang} market={market} team={team} />
    </Layout>
  )
}

export default CanadaOneboxTemplateDefault
