import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      poster={
        'https://assets.fubo.tv/marketing/lp/images/posters/NFL-TALENT-STATIC-HORZ.jpg?ch=width&auto=format,compress'
      }
      posterMobile={
        'https://assets.fubo.tv/marketing/lp/images/posters/NFL-TALENT-STATIC-VERT.jpg?ch=width&auto=format,compress'
      }
      title="Watch football without cable"
      subtitle="Stream ESPN, CBS, FOX, NFL Network & 200+ channels"
      networkSet="football"
      showRedzone={true}
    />
  )
}
