import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.liga_mx.videoUrl}
      staticAnnotation={videos.liga_mx.staticAnnotation}
      title="Watch Liga MX Live on TUDN"
      subtitle={'Access now for only $19.99 the first month'}
      tagline="STREAM ON YOUR PHONE, TV AND MORE"
      networkSet="soccerLatino"
      noteLink="/la/liga-mx-es/"
      // matchtickerSportId="199"
      // matchtickerLeagueName="Liga MX"
      // Liga MX, La Liga, Champions League
      // matchtickerLeagueId={['9208870', '3609994', '3616485']}
    />
  )
}
