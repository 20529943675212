import React, { useEffect, useState } from 'react'
import { caPlanData, newTabData } from 'src/constants/caPlanData'
import { useSelector } from 'react-redux'
import { track } from 'src/modules/analytics/track'
import { selectAnalyticsProperties } from 'src/modules/reducers/analytics'
import PlanCardWithImage from 'src/components/common/PlanCardWithImage'
import styled from 'styled-components'
import {
  selectCanadianChannels,
  selectCanadianEntertainmentChannels,
  selectCanadianEssentialsChannels,
} from 'src/modules/reducers/plans/selectors'
import TabSelector from 'src/components/common/TabSelector'

const BundlePlansCAByDuration = ({
  title = 'Which plan is right for you?',
  subtitle = 'Canada’s home to streaming live soccer and top entertainment',
  plan,
  offerCode,
  soccerPlanCardImage,
  premiumPlanCardImage,
  ctaText,
  preselectPlanLength,
  forcePlanLength,
  planCardOverride,
}) => {
  const analyticsProperties = useSelector(selectAnalyticsProperties)

  const canadianEntertainmentChannels = useSelector(selectCanadianEntertainmentChannels)
  const canadianSoccerChannels = useSelector(selectCanadianEssentialsChannels)
  const canadianPremiumChannels = useSelector(selectCanadianChannels)

  const channels = {
    entertainment: canadianEntertainmentChannels.length,
    soccer: canadianSoccerChannels.length,
    premium: canadianPremiumChannels.length,
  }

  const handleTabClick = selected => {
    setActiveTab(selected)
    const eventProperties = {
      ...analyticsProperties,
      ui_data: {
        category: 'clicked',
        event_data: {
          component: 'cta_plan_length_tab',
          content_name: selected,
        },
      },
    }
    track('lp_interaction', eventProperties)
  }

  const tabs = ['Monthly', 'Quarterly', 'Annual']

  const tabsSavings = {
    monthly: '',
    quarterly: newTabData.quarterly.tabNote,
    annual: newTabData.annual.tabNote,
  }

  const [activeTab, setActiveTab] = React.useState(tabs[2])
  const [planData, setPlanData] = React.useState(null)
  const [overrideOffer, setOverrideOffer] = useState(null)

  useEffect(() => {
    if (plan === 'entertainment') {
      setActiveTab(tabs[1])
    }
    if (preselectPlanLength) {
      setActiveTab(preselectPlanLength)
    }
    if (forcePlanLength) {
      setActiveTab(forcePlanLength)
    }
  }, [plan, preselectPlanLength, forcePlanLength])

  useEffect(() => {
    let data = { ...caPlanData(channels, planCardOverride) }

    if (forcePlanLength && offerCode) {
      setOverrideOffer(offerCode)
    }

    let filteredPlan
    let filteredPlan2
    switch (plan) {
      case 'entertainment':
        filteredPlan = 'soccer'
        break
      case 'soccer':
        filteredPlan = 'entertainment'
        break
      case 'premium':
        filteredPlan = 'entertainment'
        filteredPlan2 = 'soccer'
        break
      case 'sportsOnly':
        filteredPlan = 'entertainment'
        filteredPlan2 = 'premium'
        break
      default:
        break
    }

    if (soccerPlanCardImage) {
      data.soccer.promoImg = soccerPlanCardImage
    }

    if (premiumPlanCardImage) {
      data.premium.promoImg = premiumPlanCardImage
    }

    if (filteredPlan) {
      delete data[filteredPlan]
    }
    if (filteredPlan2) {
      delete data[filteredPlan2]
    }

    setPlanData(data)
  }, [plan, soccerPlanCardImage, premiumPlanCardImage])

  return (
    <StyledBundleSection id="select-plan">
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      {!forcePlanLength && (
        <div className="section-tabs">
          <TabSelector
            tabs={tabs}
            activeTab={activeTab.toLowerCase()}
            handleTabClick={handleTabClick}
            tabsSavings={tabsSavings}
          />
        </div>
      )}
      <div className="section-content">
        {planData &&
          Object.entries(planData).map(([cardPlan, card], index) => {
            if (card.pricePerMonth.hasOwnProperty(activeTab.toLowerCase())) {
              let listItems = [...card.listItems]
              if (cardPlan === 'entertainment') {
                if (activeTab.toLowerCase() === 'monthly') {
                  listItems.pop()
                  listItems.push({ available: true, text: 'Cloud DVR - 250 hours of space' })
                } else if (activeTab.toLowerCase() === 'quarterly') {
                  listItems.pop()
                  listItems.push({ available: true, text: 'Cloud DVR - 1000 hours of space' })
                }
              }

              // const NoFreeTrialDisclaimer = () => (
              //   <>
              //     *No free trial available. <br />
              //   </>
              // )

              // const CardFootnote = () => card.cardFootnote?.[activeTab.toLowerCase()] || null

              return (
                <PlanCardWithImage
                  key={index}
                  promoImg={card.promoImg}
                  plan={card.plan}
                  length={activeTab}
                  title={card.title}
                  subtitle={card.subtitle}
                  price={Math.round(card.pricePerMonth[activeTab.toLowerCase()] * 1e2) / 1e2}
                  discountedPrice={
                    Math.round(card.discountedPricePerMonth?.[activeTab.toLowerCase()] * 1e2) / 1e2
                  }
                  savingsNote={card.savingsNote?.[activeTab.toLowerCase()]}
                  listItems={listItems}
                  offerCode={overrideOffer || card.offerCode[activeTab.toLowerCase()]}
                  // footnote={
                  //   // <>
                  //   //   {/* {plan === 'entertainment' && card.plan !== 'entertainment' && (
                  //   //     <NoFreeTrialDisclaimer />
                  //   //   )} */}
                  //   <CardFootnote />
                  //   // </>
                  // }
                  footnote={card.cardFootnote?.[activeTab.toLowerCase()] || null}
                  ctaText={ctaText || card.ctaText}
                  // btnColor={card.ctaText && '#000000'}
                />
              )
            }
          })}
      </div>
    </StyledBundleSection>
  )
}

export default BundlePlansCAByDuration

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 90px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-225deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%);
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-family: Qanelas-Bold, sans-serif;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-family: Qanelas-Medium;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 600px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin: 0 auto 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-family: 'Qanelas-Medium', sans-serif;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
    }
  }
`
