import React from 'react'

export const caPlanCardOffers = (data, offerKey) => {
  // Adjust pricing if soccerOffer
  switch (offerKey) {
    case 'cricket':
      data.soccer.title = 'Sports + Sports Plus'
      data.soccer.subtitle = 'Best for Cricket Fans'
      data.soccer.savingsNote = {
        monthly: '',
        quarterly: 'Save $30 over 3 months*',
        annual: 'Save $134 over 12 months*',
      }
      data.soccer.totalPrice = {
        monthly: 35.98,
        quarterly: 94.96,
        annual: 333.87,
      }
      data.soccer.discountedTotalPrice = {
        monthly: '',
        quarterly: 74.96,
        annual: 278.87,
      }
      data.soccer.listItems.splice(1, 0, {
        available: true,
        text: 'Cricket Matches with Willow TV',
      })
      break
    case 'tottenhamOffer':
      data.soccer.offerCode.annual = 'canada-year-essentials-tottenham-spurs'
      data.soccer.savingsNote.annual = '$30 off your first bill'
      data.soccer.discountedTotalPrice = 249.99
      break
    case 'soccerOffer':
      data.soccer.offerCode = {
        monthly: 'ca-recu-mk-sports-mo-nt-5amtoff1-v1',
        quarterly: 'ca-recu-mk-sports-qt-nt-15amtoff1-v1',
        annual: 'ca-recu-mk-sports-yr-nt-20amtoff1-v1',
      }
      data.soccer.savingsNote = {
        monthly: '$5 off your first bill',
        quarterly: '$15 off your first bill',
        annual: '$20 off your first bill',
      }
      data.soccer.discountedTotalPrice = {
        monthly: 24.99,
        quarterly: 64.97,
        annual: 259.99,
      }
      break
    case 'menInBlazersOffer':
      data.soccer.offerCode.quarterly = 'canada-quarter-essentials-men-in-blazers'
      data.soccer.savingsNote.quarterly = '$25 off your first bill'
      data.soccer.discountedTotalPrice.quarterly = 54.97
      break
    case 'ftfOffer':
      data.soccer.offerCode.quarterly = 'canada-quarter-essentials-ftf-30-off'
      data.soccer.savingsNote.quarterly = '$30 off your first bill'
      data.soccer.discountedTotalPrice.quarterly = 49.97
      break
    case 'superChannelOffer':
      data.entertainment.title = 'Entertainment Plus'
      data.entertainment.price = 24.98
      data.entertainment.offerCode = {
        monthly: 'canada-month-entertainment-plus',
        quarterly: 'canada-quarter-entertainment-plus',
      }
      data.entertainment.totalPrice = {
        monthly: 24.98,
        quarterly: 69.99,
      }
      data.entertainment.discountedTotalPrice = {
        monthly: null,
        quarterly: 34.99,
      }
      data.entertainment.savingsNote = {
        monthly: '',
        quarterly: '50% off for 3 months',
      }
      data.entertainment.listItems.splice(-1, 0, {
        available: true,
        text: 'All Super Channel networks',
      })
      data.premium.listItems.splice(-1, 0, {
        available: false,
        text: 'Super Channel networks',
      })
      data.entertainment.listItems.splice(-1, 0, data.entertainment.listItems.splice(1, 1)[0])
      break
    case 'mlbtvOffer':
      data.soccer.title = 'Sports & MLB.TV'
      data.soccer.subtitle = 'Best for Sports Fans'
      data.soccer.offerCode = {
        monthly: 'canada-month-essentialsmlbtv-v3-promo',
        quarterly: 'canada-quarter-essentials-v5-mlbtv-promo',
        annual: 'canada-year-essentials-v5-promo-mlbtv',
      }
      data.soccer.savingsNote = {
        monthly: '$10 off for 2 months',
        quarterly: '$30 off for 2 quarters',
        annual: '$24 off the first year',
      }
      data.soccer.totalPrice = {
        monthly: 49.99,
        quarterly: 149.97,
        annual: 359.99,
      }
      data.soccer.discountedTotalPrice = {
        monthly: 39.99,
        quarterly: 119.97,
        annual: 335.88,
      }
      data.soccer.listItems.splice(1, 0, {
        available: true,
        text: 'MLB.TV, featuring 2,000+ Live Games per year',
      })
      break
    case 'sabresPriceTest':
      data.soccer.listItems.unshift({ available: true, text: 'LIVE Buffalo Sabres games' })
      data.premium.listItems.unshift({ available: true, text: 'LIVE Buffalo Sabres games' })

      data.soccer.offerCode = {
        monthly: 'canada-month-essentials-buffalo-sabres-mid-promo',
        quarterly: 'canada-quarter-essentials-buffalo-sabres-mid-promo',
        annual: 'canada-year-essentials-buffalo-sabres-mid-promo',
      }
      data.soccer.savingsNote = {
        monthly: '60% off for 12 months',
        quarterly: '62% off for 12 months',
        annual: '66% off for 12 months',
      }
      data.soccer.discountedTotalPrice = {
        monthly: 9.99,
        quarterly: 27.99,
        annual: 99.99,
      }
      break
    case 'mastercard':
      data.soccer.offerCode.quarterly = 'canada-quarter-essentials-mastercard-30-off'
      data.soccer.savingsNote.quarterly = '$30 off your first bill'
      data.soccer.discountedTotalPrice.quarterly = 49.97
      break
    default:
      break
  }

  return data
}
