import React from 'react'
import { caPlanCardOffers } from 'src/constants/caPlanCardOffers'

export const caPlanData = (channels, offerKey) => {
  // const entertainmentChannels = channels?.['entertainment'] || 64
  // const soccerChannels = channels?.['soccer'] || 73
  // const premiumChannels = channels?.['premium'] || 94
  const entertainmentChannels = 64
  const soccerChannels = 79
  const premiumChannels = 101

  let data = {
    entertainment: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_entertainment-2.jpg?w=451&ch=width&auto=format,compress',
      plan: 'entertainment',
      title: 'Entertainment',
      subtitle: 'Best for Families',
      listItems: [
        { available: true, text: `${entertainmentChannels} channels - 1000's of popular titles` },
        { available: false, text: 'Premier League matches' },
        { available: true, text: 'Lifestyle channels - HGTV & more' },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Cloud DVR - Up to 1000 hours of space' },
      ],
      footnote: '',
      selectionHeader: 'Choose monthly or quarterly billing',
      selectionSubheader: '1000 hours of Cloud DVR when choosing a quarterly plan.',
      offerCode: {
        monthly: 'ca-recu-ga-enterta-mo-nt-v1',
        quarterly: 'ca-recu-ga-enterta-qt-nt-v1',
      },
      totalPrice: {
        monthly: 16.99,
        quarterly: 47.97,
      },
      savings: {
        monthly: '250 hours of DVR',
        quarterly: '1000 hours of DVR',
      },
      ctaText: 'Get Access',
      // ctaText: 'Start For Free',
    },
    soccer: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_soccer-2.jpg?w=451&ch=width&auto=format,compress',
      plan: 'soccer',
      title: 'Sports',
      subtitle: 'Best for Soccer and Sports Fans',
      listItems: [
        { available: true, text: `${soccerChannels} channels - Soccer and sports` },
        { available: true, text: 'All Premier League matches' },
        { id: '4k', available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Serie A, Coppa Italia, Ligue 1 & more' },
        { available: true, text: 'Cloud DVR - 250 hours of space' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),
      selectionHeader: 'Choose monthly, quarterly or annual billing',
      selectionSubheader: 'Save up to 22% when choosing an annual plan.',
      offerCode: {
        monthly: 'ca-recu-ga-sports-mo-nt-v1',
        quarterly: 'ca-recu-ga-sports-qt-nt-v1',
        annual: 'ca-recu-ga-sports-yr-nt-v1',
      },
      savingsNote: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      totalPrice: {
        monthly: 29.99,
        quarterly: 79.97,
        annual: 279.99,
      },
      // Promo pricing
      discountedTotalPrice: {
        monthly: null,
        quarterly: null,
        annual: null,
      },
      savings: {
        monthly: '',
        quarterly: 'Save 11%',
        annual: 'Save 22%',
      },
      ctaText: 'Get Access',
      // ctaText: 'Start For Free',
    },
    premium: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_premium-2.jpg?w=451&ch=width&auto=format,compress',
      plan: 'premium',
      title: 'Premium',
      subtitle: 'Best for Everyone',
      listItems: [
        { available: true, text: `${premiumChannels} channels - Entertainment and sports` },
        { available: true, text: 'All Premier League & Serie A matches' },
        { available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Includes NBA TV' },
        { available: true, text: 'Lifestyle channels - HGTV & more' },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Cloud DVR - 1000 hours of space' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),
      selectionHeader: 'Choose monthly, quarterly or annual billing',
      selectionSubheader: 'Save up to 23% when choosing an annual plan.',
      offerCode: {
        monthly: 'ca-recu-ga-premium-mo-nt-v1',
        quarterly: 'ca-recu-ga-premium-qt-nt-v1',
        annual: 'ca-recu-ga-premium-yr-nt-v1',
      },
      // Promo pricing
      savingsNote: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      totalPrice: {
        monthly: 42.99,
        quarterly: 104.98,
        annual: 395.88,
      },
      // Promo pricing
      discountedTotalPrice: {
        monthly: null,
        quarterly: null,
        annual: null,
      },
      savings: {
        monthly: '',
        quarterly: 'Save 18%',
        annual: 'Save 23%',
      },
      ctaText: 'Get Access',
      // ctaText: 'Start For Free',
    },
  }

  // adjust card data for promo offers
  if (offerKey) data = caPlanCardOffers(data, offerKey)

  // calculate per month numbers
  data = calculatePrices(data)

  console.log('ca plans', data)

  return data
}

const calculatePrices = data => {
  let newData = {
    entertainment: {
      ...data.entertainment,
      price: `C$${data.entertainment.totalPrice.monthly}/mo`,
      pricePerMonth: {
        monthly: data.entertainment.totalPrice.monthly,
        quarterly: data.entertainment.totalPrice.quarterly / 3,
      },
      cardFootnote: {
        monthly: createFootnote(data.entertainment, 'Monthly'),
        quarterly: createFootnote(data.entertainment, 'Quarterly'),
      },
    },
    soccer: {
      ...data.soccer,
      price: 'Starting at C$23.33/mo',
      pricePerMonth: {
        monthly: data.soccer.totalPrice.monthly,
        quarterly: data.soccer.totalPrice.quarterly / 3,
        annual: data.soccer.totalPrice.annual / 12,
      },
      discountedPricePerMonth: {
        monthly: data.soccer.discountedTotalPrice.monthly,
        quarterly: data.soccer.discountedTotalPrice.quarterly / 3,
        annual: data.soccer.discountedTotalPrice.annual / 12,
      },
      cardFootnote: {
        monthly: createFootnote(data.soccer, 'Monthly'),
        quarterly: createFootnote(data.soccer, 'Quarterly'),
        annual: createFootnote(data.soccer, 'Annual'),
      },
    },
    premium: {
      ...data.premium,
      price: 'Starting at C$23.33/mo',
      pricePerMonth: {
        monthly: data.premium.totalPrice.monthly,
        quarterly: data.premium.totalPrice.quarterly / 3,
        annual: data.premium.totalPrice.annual / 12,
      },
      discountedPricePerMonth: {
        monthly: data.premium.discountedTotalPrice.monthly,
        quarterly: data.premium.discountedTotalPrice.quarterly / 3,
        annual: data.premium.discountedTotalPrice.annual / 12,
      },
      cardFootnote: {
        monthly: createFootnote(data.premium, 'Monthly'),
        quarterly: createFootnote(data.premium, 'Quarterly'),
        annual: createFootnote(data.premium, 'Annual'),
      },
    },
  }

  newData.entertainment.price = lowestPrice(newData.entertainment)
  newData.soccer.price = lowestPrice(newData.soccer)
  newData.premium.price = lowestPrice(newData.premium)

  return newData
}

const createFootnote = (data, duration) => {
  const totalPrice = data.totalPrice[duration.toLowerCase()]
  const discountedTotalPrice = data.discountedTotalPrice?.[duration.toLowerCase()]
  const name = data.title

  let numOfMonths
  switch (duration) {
    case 'Annual':
      numOfMonths = 12
      break
    case 'Quarterly':
      numOfMonths = 3
      break
    default:
      break
  }
  if (discountedTotalPrice && numOfMonths) {
    return `*Discount based on ${numOfMonths} months of service of ${name} ${duration} plan. Prepaid for ${numOfMonths} months at C$${discountedTotalPrice}. Renews at full price of C$${totalPrice}.`
  } else if (numOfMonths) {
    return `*Prepaid for ${numOfMonths} months at C$${totalPrice}`
  } else if (discountedTotalPrice) return `Renews at full price of C$${totalPrice}.`
}

const lowestPrice = data => {
  const lowest = Math.min(
    data.pricePerMonth.monthly,
    data.pricePerMonth.quarterly,
    data.pricePerMonth.annual,
    data.discountedPricePerMonth?.monthly,
    data.discountedPricePerMonth?.quarterly,
    data.discountedPricePerMonth?.annual
  )

  const highest = Math.max(
    data.pricePerMonth.monthly,
    data.pricePerMonth.quarterly,
    data.pricePerMonth.annual,
    data.discountedPricePerMonth?.monthly,
    data.discountedPricePerMonth?.quarterly,
    data.discountedPricePerMonth?.annual
  )

  return `${lowest !== highest ? 'Starting at ' : ''}C$${lowest}/mo`
}

export const newTabData = {
  monthly: {
    tabNote: '',
  },
  quarterly: {
    tabNote: '',
    // tabNote: 'Save up to 13%',
  },
  annual: {
    tabNote: '',
    // tabNote: 'Save up to 26%',
  },
}
