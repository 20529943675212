import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      // video={videos.generic_soccer.videoUrl}
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/equipo-nacional-mx-bg.jpg?ch=width&auto=format,compress"
      title="Mira al equipo de México en vivo"
      subtitle="Accede ahora por solo $19.99 el primer mes"
      tagline="Más de 50 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="soccerLatino"
    />
  )
}
