import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import ChannelSectionSimple from 'src/components/common/ChannelSectionSimple'

import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAnalyticsParameters,
  selectAnalyticsProperties,
} from 'src/modules/reducers/analytics'
import { createUrl } from 'src/modules/utils/createUrl'
import { track } from 'src/modules/analytics/track'
import { Link } from 'gatsby'
import LAPlanCards from 'src/components/common/LAPlanCards'
import { offerSlug } from 'src/constants/laPlanData'
import BundlePlansLatinoQuarterlyOnly from 'src/components/common/BundlePlansLatino/quarterlyOnly'
import MatchTicker from 'src/components/common/MatchTicker'

const LatinoTemplateDefault = ({
  lang = 'en',
  market = 'latino',
  ctaText,
  // Offer Code
  offerCode = offerSlug.monthly,
  offerBanner,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  title,
  subtitle,
  tagline,
  video,
  staticAnnotation,
  annotations,
  poster = 'https://assets-imgx.fubo.tv/marketing/lp/images/posters/AdobeStock_210894329.jpg?w=2048&ch=width&auto=format,compress',
  posterMobile,
  noteLink,
  // Plan Cards
  disablePlanCards,
  quarterlyOnly,
  discountedPrice,
  // Network Section
  networkHeadline,
  networkSet = 'entertainmentLatino',
  customNetworks,
  // Others
  team,
  // AB Test for MatchTicker
  matchtickerSportId,
  matchtickerLeagueName,
  matchtickerLeagueId,
  // Force Anchor Link
  forceAnchorLink,
}) => {
  let metaDescription =
    pageDescription ||
    (lang === 'es'
      ? 'Transmite canales de TUDN, Univision, beIN SPORTS y más con Fubo. No te pierdas ni un momento de los equipos y programas que más te gustan: visualízalos en directo y a la carta desde tu ordenador, smartphone, tableta, Roku, Fire TV, Apple TV y Chromecast.'
      : "Stream TUDN, Univision, beIN SPORTS channels and more with Fubo. Don't miss a moment of the teams and shows you love - watch live and on-demand from your desktop, smartphone, tablet, Roku, Fire TV, Apple TV and Chromecast.")
  const analyticsProperties = useSelector(selectAnalyticsProperties)

  const isAnchor = forceAnchorLink && !disablePlanCards && !quarterlyOnly ? '#select-plan' : null

  const originalParams = useSelector(selectAnalyticsParameters)
  let noteUrl
  if (noteLink) {
    noteUrl = createUrl(noteLink, originalParams)
  }

  const handleLocaleSwitch = () => {
    const eventProperties = {
      ...analyticsProperties,
      'ui_data.category': 'clicked',
      'ui_data.event_data': {
        component: 'latino_locale_switch',
        destination_url: noteUrl,
      },
    }
    track('lp_locale_switch', eventProperties)
  }

  let noteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        To learn more in English, <span>click here</span>.
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Para traducir a español, <span>haga clic aquí</span>.
      </Link>
    )

  if (!offerBanner) {
    if (lang == 'es') {
      offerBanner = <>✔ ¡Super ofertón! Accede ahora por solo $19.99 el primer mes</>
    } else {
      offerBanner = <>✔ Great offer! Access now for only $19.99 you first month</>
    }
  }

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      forceLang={lang === 'es' ? 'es' : false}
    >
      <LogoSyncContextProvider annotations={annotations}>
        <PageWrapper>
          <SEO
            lang={lang}
            market={market}
            title={pageTitle || title}
            description={metaDescription}
          />
          {matchtickerLeagueId && (
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
            />
          )}
          <HeroSectionNew
            lang={lang}
            market={market}
            heroBgVideo={video}
            title={title}
            subtitle={subtitle}
            tagline={tagline}
            staticAnnotation={staticAnnotation}
            posterImg={poster}
            ctaText={ctaText}
            noteText={noteUrl && noteText}
            ctaHrefOverride={isAnchor}
            posterImgMobile={posterMobile}
          />
          {quarterlyOnly ? (
            <BundlePlansLatinoQuarterlyOnly lang={lang} ctaText={ctaText} />
          ) : (
            !disablePlanCards && (
              <LAPlanCards
                lang={lang}
                ctaText={ctaText}
                offerCode={offerCode}
                discountedPrice={discountedPrice}
              />
            )
          )}
          <NetworkSection
            lang={lang}
            market={market}
            networkHeadline={networkHeadline}
            networkSet={networkSet}
            customNetworks={customNetworks}
            ctaHrefOverride={isAnchor}
            ctaText={ctaText}
          />
          <ChannelSectionSimple lang={lang} market={market} />
          <DealSection lang={lang} market={market} />
          <PlatformSection
            lang={lang}
            market={market}
            ctaHrefOverride={isAnchor}
            ctaText={ctaText}
          />
          <FAQSection lang={lang} market={market} team={team} />
        </PageWrapper>
      </LogoSyncContextProvider>
    </Layout>
  )
}

export default LatinoTemplateDefault

const PageWrapper = styled.div`
  .hero-content-text {
    h1 span {
      display: inline-block;
    }
  }
`
