import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.latino.videoUrl}
      annotations={videos.latino.annotations}
      title="Save big on live TV for your family"
      subtitle="Access now for only $19.99 the first month"
      tagline="60+ channels of sports, news, novelas, and entertainment in Spanish. Additional taxes and fees may apply."
      networkSet="entertainmentLatino"
    />
  )
}
